














import { Component, Prop, Watch } from 'nuxt-property-decorator';
import Vue from 'vue';
import { AlignementOptions, BlokContentText, TextColumnOptions } from '~/types';

@Component
export default class ContentText extends Vue {
  @Prop() blok!: BlokContentText;
  get columns (): TextColumnOptions {
    return this.blok.columns ? this.blok.columns : '1';
  }
  get alignement (): AlignementOptions {
    return this.blok.alignement ? this.blok.alignement : 'left';
  }

  $refs: { textContainer: HTMLElement };

  /*
  @Watch('blok', { deep: true })
  headingResetStyling () {
    if (process.browser) {
      const allHeadings = this.$refs.textContainer.querySelectorAll('h1, h2, h3, h4, h5');
      allHeadings.forEach((element:HTMLElement) => {
        const children = Array.from(element.children);
        if (children.filter(el => el.classList.contains('title')).length) {
          element.style.padding = '0px';
        }
      });
    }
  }
  mounted () {
    this.headingResetStyling();
  } */
}
